@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

.roboto{
    font-family: 'Roboto', sans-serif;
}
body {
    overflow-x: hidden;
    background: #050505 !important;
    font-family: 'Orbitron', sans-serif;
  }
 html ,h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family: 'Orbitron', sans-serif;
}
:root{
--themeyellow: #ECFF00;
--grayss: #9E9E9E;
--aqua:  #0AD2B9 !important;
}
.aqua {
    color: #0AD2B9 !important;
}

.yellow ,.yellowtxt{
    color: var(--themeyellow) !important;
}

.red {
    color: #F8658D !important;
}

.green {
    color: #03FF48 !important;
}

.gray {
    color: gray !important;
}
.themebtn.big {
    max-width: 170px;
    width:100%;
}
.subhead {
    color: #757272;
    text-align: center;
    font-family: roboto;
}
.themecontainer {
    width: 95%;
}
.d-grid{
    display: grid;
}
.br-20{
    border-radius: 20px !important;
}
.yelloweye .fa {
    background: var(--themeyellow);
    padding: 10px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: #000;
  }
  /* .asrt-table-head .col-md-6:last-child {
    display: none;
  } */


#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

.img-fluid.proofThumb {
    max-height: 135px;
    border-radius: 5px;
    display: block;
    margin: 15px 0 0;
}


#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: calc(100vw - 1px);
    padding-top: 65px;
    padding-bottom: 35px;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

#menu-toggle {
    color: #fff;
    border: 2px solid #3d3d3d;
    padding: 5px 7px;
    margin: 0 0px 0 0;
    line-height: 0;
    position: fixed;
    top: 16px;
    z-index: 9;
    left: 15px;
}

.navbar.adminNav {
    position: fixed;
    display: flex;
    align-items: center;
    top:0px;
    width: 100%;
    padding: 10px 15px 10px 65px;
    z-index: 9;
}

.text-small {
    font-size: 0.9rem;
}

.chat-box {
    height: 510px;
    overflow-y: auto;
}

.rounded-lg {
    border-radius: 0.5rem;
}

input::placeholder {
    font-size: 0.9rem;
    color: #999;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }



    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }

    .adminNav .dropdown-menu a {
        display: block;
        color: #000;
        padding: 8px 10px;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

/* .container input {
    position: static !important;
    opacity: 1 !important;
    cursor: pointer !important;
    width: 100% !important;
} */

.container [type=checkbox] {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    height: 0 !important;
    width: 0 !important;
}

.chip-body {
    height: 37px !important
}

.multiSelect {
    margin: 0 !important;
}

.tableHead h2 {
    font-size: 24px;
    color: #d6b032;
    text-align: left;
    margin: 12px 15px 15px;
    font-weight: 600;
}

.loadingContent {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #000; */
    overflow: hidden;
}
.loading {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .loading div {
    position: absolute;
    background: #032eff;
    opacity: 1;
    border-radius: 50%;
    animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .loading div:nth-child(2) {
    animation-delay: -.7s;
  }
  @keyframes loading {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }

  .chat_content_center {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #e6e6e6;
}
.chat_content_center p {
    margin: 0;
    text-align: center;
}
.chat_content_center h4 {
    margin: 0;
    font-weight: 600;
    padding: 4px 0px;
    color: #0b3e8f;
}
.chat_conte_po h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0px;
    color: #fff;
}
.chat_conte_po p {
    margin: 0;
    font-size: 13px;
    /* margin: 0; */
    font-weight: 600;
}
.ma-aut0pdd {
    margin: 11px auto;
}
.chat_conte_po a {
    color: #5d5e65 !important;
}
.chat_box_dark {
    min-height: 338px;
    background: #f6f8fe;
    border-radius: 5px;
    border: 1px solid #f6f8fe;
}
.footer_chat {
    width: 100%;
    padding: 25px;
}
.footer_chat .form-group {
    margin: 0;
}
.footer_chat .form-control {
    background: #e3e6f2;
    padding: 14px 11px;
    border-radius: 5px;
    border: 1px solid #e3e6f2;
    height: 45px;
    color: #666;
    text-transform: capitalize;
}
.button_section_po_chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.chat_message_section {
    min-height: 260px;
    height: 352px;
    overflow: auto;
}
.footer_chat .fa-paper-plane {
    float: right;
    margin-right: 20px;
    margin-top: -31px;
    font-size: 20px;
    color: #0b3e8f;
    cursor: pointer;
}

.footer_chat .fa-paperclip {
    float: left;
    margin-right: 60px;
    margin-top: -31px;
    font-size: 20px;
    color: #0b3e8f;
    cursor: pointer;
}
.message_section {
    padding: 22px 12px;
}
.message_section div {
    width: auto;
    padding: 6px 3px;
    position: relative;
    /* margin: 22px 9px; */
}
.message_section p {
    margin: 0;
    padding: 10px 30px;
    color: #666;
    background: #e3e6f2;
    font-size: 13px;
}
.message_section span {
    position: absolute;
    top: -16px;
    /* width: 100%; */
    right: 4px;
    color: #6f7075;
    font-size: 12px;
}
.message_send  span {
    left: 4px !important;
}
.messAge_recive  span {
    left: 4px !important;
}
.messAge_recive {
    padding: 22px 12px;
    display: flex;
    justify-content: flex-start;
}
.message_send {
    padding: 22px 12px;
    display: flex;
    justify-content: flex-end;
}

  
@media (max-width: 767px) {
    .modal-content label {
        font-size: 12px;
      }
    .adminNav .dropdown-menu {
        background: transparent;
        margin-top: 0;
        padding-top: 0;
        border: 0;
    }

    .adminNav .dropdown-menu a {
        display: block;
        color: #fff;
        padding: 10px 0;
    }

    .adminNav .dropdown-menu a:hover {
        color: #d6b032;
        text-decoration: none;
    }

    .adminNav .dropdown-item:hover,
    .adminNav .dropdown-item:focus {
        background: transparent;
        text-decoration: none;
    }

    .adminNav.navbar-dark .navbar-toggler {
        color: #fff;
        border: 2px solid #3d3d3d;
        padding: 5px 7px;
        margin: 0 0px 0 0;
        line-height: 0;
        font-size: inherit;
        outline: none;
        top: 16px;
        z-index: 9;
    }

    .navbar.adminNav {
        position: fixed;
        display: flex;
        align-items: center;
        padding: 10px 15px 10px 65px;
        z-index: 9;
        width: 100%;
    }
}
.bg-dark {
    background-color: #090909 !important;
}

.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    z-index: 55555;
}
.card
{
  background-color: #1a1b1c !important;
  border-radius: 0px !important;
}
/* input,.form-control,.container input
{
  background-color: transparent !important;
  border-color: #59615f !important;
  border-radius: 8px !important;
  color:#787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
} */
label
{
  color:#d1d1d1 !important;
}
.page-item .page-link input {
    color: #fff;
    background-color: transparent;
  }
.btn-primary
{
  background: var(--themeyellow) !important;
    border: 1px solid var(--themeyellow) !important;
    color: #000 !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    box-shadow: none!important;
    transition: .5s;
    font-size: 14px !important;
}


.flex_center a:hover
{
  color:#fff !important;
}
.flex_center .floatinglabel .right a.view_eyw_icon_color:hover

{
color:#9da723 !important;
}
.flex_center a.view_eyw_icon_color:hover

{
color:#9da723 !important;

}
.grey-text
{
  color:#ccc !important;
}
a::placeholder
{
  color:#ccc !important;

}
.flex_center
{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.flex_center .card h2
{
    font-size: 25px !important;
    margin-bottom: 20px !important;
}
.list-group-item
{
    background-color: #1a1b1c !important;
    /* border-color:#3d3d3d !important; */
    /* border: 1px solid #3d3d3d !important; */
}
#wrapper .border-right,.border-right
{
    border-right:1px solid #3d3d3d !important
}
.list-group-item-action
{
    color:#fff !important;
}

@media only screen and (max-width:575px)
{
    .input-group.asrt-page-length
    {
        justify-content: center !important;
    }
    .table-foot .col-md-6:first-child,.table-head .col-md-6:first-child
    {
        margin-bottom: 20px;
    }
    .input-group.asrt-page-length
    {
        justify-content: center !important;
    }
    /* .table-foot
    {
        margin-left: 0px !important;
        margin-right: 0px !important;
    } */
    .page-link
    {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .pagination.justify-content-end 
    {
        justify-content: center !important;
    }
    .table-foot .col-md-6.text-right, .table-foot .col-md-6,
    .table-head .col-md-6.text-right, .table-head .col-md-6
    {
        text-align: center !important;
    }
    .table-foot .col-md-6.text-right,    .table-foot .col-md-6.text-right .pull-right,
    .table-head .col-md-6.text-right,    .table-head .col-md-6.text-right .pull-right

    {
        float: unset !important;
    }
    .table_filter
    {
        margin-right: 0px !important;
        width: 100% !important;
    }
    .block_mob.d-flex
    {
        display: block !important;
        text-align: center;
    }
    .block_mob.d-flex a
    {
        display: block !important;

    }
    .flex_center .card
    {
        min-width: 290px !important
    }
}
@media only screen and (min-width:576px)
{
    .flex_center .card
    {
        min-width: 350px !important
    }
}
.navbar-brand img
{
    max-width: 100px;
}
.text-secondary,#page-content-wrapper .text-secondary
{
    color: var(--themeyellow) !important;
}

th.sortable
{
    color:#fff !important;
}
.table thead th,.table,.table thead td,.table-bordered td
{
    border-color: #3d3d3d !important;
    border-width: 1px !important;
    color:#fff !important;

}
.table-bordered td
{
    color:#fff !important;
    font-size: 14px !important;
}
.btn-danger
{
    background: #a81e3a !important;
    border: 1px solid #a81e3a !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    box-shadow: none!important;
    transition: .5s;
    font-size: 14px !important;
}
.btn-danger:hover
{
background: transparent !important;
border: 1px solid #a81e3a !important;
color: #a81e3a !important;
}
th.sortable
{
    filter: brightness(0) invert(1);
}
.sidebar_whole
{
    position: fixed;
    z-index: 1;
    min-height: 100vh;
    background: #1a1b1c;
    /* overflow-y: scroll; */
    top:63px;
    width: 250px !important;
}
@media only screen and (min-width:992px)
{
    #page-content-wrapper {
        min-width: 0;
        width: calc(100% - 250px);
    margin-top: 1%;
    margin-left: 250px;
    }
    .shrink_whole #page-content-wrapper
    {
        width: 100% !important;
        margin-left: 0px !important;

    }
}
.user_management_table td:first-child,.user_management_table th:first-child,
.user_management_table td:nth-child(4),.user_management_table th:nth-child(4),
.user_management_table td:nth-child(5),.user_management_table th:nth-child(5),
.passbook_history_table td:first-child,.passbook_history_table th:first-child
{
    min-width: 150px !important;
}
.passbook_history_table td:nth-child(2),.passbook_history_table th:nth-child(2)
{
    min-width: 100px !important;
}
.passbook_history_table td:nth-child(7),.passbook_history_table th:nth-child(7),
.passbook_history_table td:nth-child(8),.passbook_history_table th:nth-child(8)
{
    min-width: 150px !important;
}
.user_management_table td:nth-child(2),.user_management_table th:nth-child(2)
{
min-width: 100px !important;
}
.user_management_table td:nth-child(6),.user_management_table th:nth-child(6)
{
    min-width: 170px !important;
}
.kyc_table th:nth-child(2),.kyc_table td:nth-child(2),
.kyc_table th:last-child,.kyc_table td:last-child
{
min-width: 150px !important;
}
.table-foot
{
    color:#fff !important;
}
.page-item .page-link
{
    background-color: transparent !important;
    color:#fff !important;
    border-color:#3d3d3d !important;
}
.page-item.disabled .page-link
{
    background-color: transparent !important;
    color:#3d3d3d !important;
    border-color:#3d3d3d !important;
}
.modal-header,.modal-footer
{
    border-color:#3d3d3d !important;

}
.modal.show
{
    padding-right: 0px !important;
}
.table_filter
{
    max-width: 100% !important;
}
.page-item .page-link a
{
    color:#fff !important;
}
.page-item .page-link input
{
    height: unset !important;
}

@media only screen and (min-width:576px) and (max-width:767px)
{
    .input-group.asrt-page-length
    {
        justify-content: center !important;
    }
    .table_filter
    {
        margin-right: 0px !important;
    }
    .table-foot .col-md-6:first-child,.table-head .col-md-6:first-child
    {
        margin-bottom: 20px;
    }
    /* .table-foot
    {
        margin-left: 0px !important;
        margin-right: 0px !important;
    } */
    .page-link
    {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .pagination.justify-content-end 
    {
        justify-content: center !important;
    }
    .table-foot .col-md-6.text-right, .table-foot .col-md-6,
    .table-head .col-md-6.text-right, .table-head .col-md-6
    {
        text-align: right !important;
    }
    .table-foot .col-md-6.text-right,    .table-foot .col-md-6.text-right .pull-right,
    .table-head .col-md-6.text-right,    .table-head .col-md-6.text-right .pull-right

    {
        float: unset !important;
    }
}
.modal-content
{ background-color: #1a1b1c !important;
    border-radius: 0px !important;
}
.modal-title,.close
{
    color:#fff !important;
}
.asrt-page-length .input-group-addon
{
    background-color: transparent !important;
}
#as-react-datatable-container select
{
    height: unset !important;
color:#787272 !important;

}
.input-group.asrt-page-length
{
    align-items: center;
}
.input-group.asrt-page-length .input-group-text
{
color:#ccc !important;
}
@media only screen and (min-width:768px) and (max-width:991px)
{
    .modal .page-link
    {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
}
.btn
{
    min-width: 32px !important;
}
.user_asset_modal_table .table-body .col-md-12
{
    display: block;
    width: 100%;
    overflow-x: auto;
}
.btn-info
{
    background: transparent !important;
    border: 1px solid #242827 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    box-shadow: none!important;
    transition: .5s;
    font-size: 14px !important;
}
.btn-info:hover
{
    background: #242827 !important;
    color:#fff !important;

}
.popUpSpace
{
    color:#fff !important;
}
.word_brak
{
    word-break: break-all;
}
.file_a
{
    color: #02c076 !important;
}
.file_a .fa{
    margin-right: 5px !important;
}
.file_a:hover
{
    color: #fff !important;
}
.support_table th:nth-child(3),
.support_table td:nth-child(3),
.support_table th:nth-child(2),
.support_table td:nth-child(2),
.support_table th:nth-child(5),
.support_table td:nth-child(5)
{
    min-width: 150px !important;
}
.support_table th:first-child,
.support_table td:first-child
{
    min-width: 100px;
}
.chat-box.bg-white
{
    background-color: #1a1b1c !important;
}
.chat-box.bg-white .bg-light
{
    background-color: #000 !important;
    color:#fff !important;
}
.bg-input-dark
{
    background-color: #000 !important;
    color: #787272 !important;
    height: unset !important;
    resize: none !important;
}
.bg-light-dark
{
    background-color: #000 !important;
    color: #787272 !important;
    height: unset !important;
    resize: none !important; 
}
.input-grp-dark .btn-link
{
    background-color: #585b5e !important;
    color: #fff !important;  
    border-radius: 0px !important;
}
/* .input-grp-dark 
{
    border-left: 1px solid #3c3d3d !important;

} */
.input-grp-dark .btn-link:hover,.ticketDetails .fa:hover
{
    color: #02c076 !important;
}
.ticketDetails .fa
{
    color: #fff !important;  
    font-size: 16px !important;
    margin-left: 10px !important;
}
.table-body .col-md-12 {
    display: block;
    width: 100%;
    overflow-x: auto;
}
.table-foot
{
    margin-top: 10px;
}
#page-content-wrapper .mt-2.text-secondary
{
margin-bottom: 30px !important;
}
.btn-outline-primary,.btn-secondary
{
    background: transparent !important;
    border: 1px solid #242827 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    box-shadow: none!important;
    transition: .5s;
    font-size: 14px !important;
}
.btn-outline-primary:hover,.btn-secondary:hover
{
    background: #242827 !important;
    border: 1px solid #242827 !important;
    color: #fff !important;  
}
.custom-select
{
    background-color: #1a1b1c !important;
    /* background: #1a1b1c !important; */

    border-color: #59615f  !important;
    border-radius: 8px !important;
    color:#787272 !important;
    box-shadow: 0 0 0 0rem #fff !important;
    /* height: 52px !important; */
}
.custom-select option
{
    background-color: #1a1b1c !important;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    background-color: transparent !important;
    border: 1px solid #ced4da;
    border-color: #59615f !important;
    border-radius: 8px !important;
    color: #787272 !important;
    box-shadow: 0 0 0 0rem #fff !important;
    height: 52px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 12px;
    cursor: pointer;
}
.table_filter
{
    margin-right: 0px !important;
}
/* .cke_inner,.cke_top,.cke_chrome
{
    background: #1a1b1c !important;
    border-color:#242827 !important;
}
.cke_wysiwyg_frame, .cke_wysiwyg_div,.cke_wysiwyg_frame body
{
    background-color: #1a1b1c !important;
    background: #1a1b1c !important;

}
.cke_top {
    border-bottom: 1px solid #242827 !important;
}
a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active,
.cke_combo_on a.cke_combo_button, .cke_combo_off a.cke_combo_button:hover, .cke_combo_off a.cke_combo_button:focus, .cke_combo_off a.cke_combo_button:active
{
    background: #000 !important;
    border: 1px #242827 solid !important;
}
.cke_toolbar_separator
{
    background-color: #242827 !important;
}
.cke_toolgroup a.cke_button:last-child:after, .cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child:after,
.cke_combo:after
{

    border-color:#242827 !important;
} */
.email_table th:nth-child(2),.email_table td:nth-child(2)
{
    min-width: 150px !important;
}
/* form .row
{
    align-items: center;
} */
/* form .row label
{
    margin-bottom: 0px !important;
} */
.dropdown-menu
{
    background: #1a1b1c !important;
    border-radius: 0px !important;
}
.dropdown-menu a
{
    color:#fff !important;
}
.dropdown-menu a:hover
{
    font-weight: unset !important;
    color: #02c076 !important;
}
.login_history_table td:last-child,.login_history_table th:last-child,
.login_history_table td:nth-child(8),.login_history_table th:nth-child(8),
.login_history_table td:nth-child(6),.login_history_table th:nth-child(6)
{
    min-width: 150px !important;
}
.login_history_table td:first-child,.login_history_table th:first-child,
.login_history_table td:nth-child(3),.login_history_table th:nth-child(3),
.login_history_table td:nth-child(2),.login_history_table th:nth-child(2)
{
    min-width: 100px !important;
}
.basic-multi-select
{
    z-index: 1 !important;
}
/* .basic-multi-select>div
{
    z-index: 99999 !important;
} */
/* .mySelect__value-container{
    height: 35px !important;
   } */
.select__control,.select__indicators,.select__menu
{
    /* background-color: #000 !important;
    background: #000 !important;
    border-color: #242827 !important; */
    box-shadow: 0 0 0 0 #fff !important;
}
.input_grp_width input
{
    width: 1% !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.input_grp_width .input-group-text
{
background-color: transparent !important;
border-color: #59615f !important;
color: #fff !important;
}
.input_grp_width .input-group-text i
{
color: #fff !important;

}
.input_grp_width .input-group-text
{
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}
.input_grp_width .input-group-text i:hover
{
    color: #02c076 !important;
}
/* .select__multi-value
{
    background-color: #1a1b1c !important;
    background: #1a1b1c !important;
    color:#fff !important; 
}
.select__multi-value__label
{
    color:#fff !important; 

} */
/* .select__menu div:hover
{
    background-color: #1a1b1c !important;
    background: #1a1b1c !important;
    color:#fff !important; 
} */
.settings_head_menu a
{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.select__menu
{
    background: #000 !important;
}
.btn-info
{
    width: auto !important;
}
.floatinglabel {
    position: relative;
  }
  .floatinglabel label {
    /* position: absolute; */
    left: 16px;
    top: -6px;
    font-size: 12px;
    color: #fff;
    z-index: 1;
  }
  .floatinglabel label::after {
    content: "";
    position: absolute;
    background: #000;
    height: 1px;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
  }
  .floatinglabel label::after {
    background: unset !important;
  }
  .floatinglabel .form-control ,.custom-file-upload{
    background: transparent;
    border-color: #636363;
    border-radius: 10px;
    height: 46px !important;
    color: #c7c7c7 !important;
    appearance: auto !important;
    -moz-appearance: auto;
    -webkit-appearance: auto;
    font-size: 12px !important;
  }
  .floatinglabel .right {
    position: absolute;
    right: 10px;
    top: 44px;
    color: #acacac;
  }
  .form-check, label {
    font-family: 'Orbitron', sans-serif;
  }
  /* .btn-light
  {
      background: #02c076 !important;
      border: 1px solid #02c076 !important;
      color: #fff !important;
      text-transform: capitalize !important;
      font-weight: 600 !important;
      box-shadow: none!important;
      transition: .5s;
      font-size: 14px !important;
  }
  .btn-light:hover
  {
      background-color: transparent !important;
      border: 1px solid #02c076 !important;
      color: #02c076 !important;
  
  } */
  .themebtn, .btn.btn-light {
    background-color: #ECFF03;
    border: 1px solid #ECFF03;
    border-radius: 10px;
    padding: 10px 30px !important;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    box-shadow: 0 0 5px 5px #222121;
  }
  .themebtn:hover, .btn.btn-light:hover {
    border: 1px solid #ECFF03;
    background-color: transparent !important;
    color: #ECFF03 !important;
  }
  .form-control:focus{
    background-color: transparent !important;
    box-shadow: none !important;
    border-color:#888889 !important;
  }

  .table_filter .form-control
  {
    border-color:#dee2e65e !important;
  }
  .flexing_disable_btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }

