@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

.roboto {
    font-family: 'Roboto', sans-serif;
}
body {
  margin: 0;
  font-family: 'Orbitron', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050505 !important;
}

.flexHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#plus_minus-button {
  margin-top: 30px;
}

#nav_links a {
  color: #000;
  text-decoration: none;
}

#nav_links a:hover {
  color: rgba(16, 153, 245, 0.836);
  text-decoration: none;
  font-weight: bold;
}



.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: auto !important;
}

header,
main,
footer {
  padding-left: 300px;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: auto !important;
}

@media screen and (min-width:768px) {}

@media only screen and (max-width : 992px) {

  header,
  main,
  footer {
    padding-left: 0;
  }
}

.navbar.bg-dark {
  background-color: #090909 !important;
}

.border-right
{
    border-right:1px solid #3d3d3d !important
}
.asrt-td-loading
{
  background-color: transparent !important;
  color:#fff !important;
}

.link_new_green
{
  color:#02c076 !important;
}

.link_new_green:hover
{
  color:#fff !important;
}
.react-datepicker-wrapper input
{
  border:1px solid #59615f  !important;
  padding: 10px !important;
  width: 100% !important;
  display: block !important;
}
.react-datepicker-wrapper
{
  width: 100% !important;
  display: block !important;
}
.react-datepicker-wrapper input:focus-visible

{
  border:1px solid #59615f  !important;
 box-shadow: 0 0 0 0rem #fff !important;
 outline: -webkit-focus-ring-color auto 0px !important;

}
.link_green_add
{
  color: #02c076 !important;
}
.link_green_add:hover
{
  color: #fff !important;
}
.navbar_mobile_menu
{
  position: fixed;
  right: 15px;
}

.navbar_mobile_menu .settings_head_menu 
{
left:unset !important;
right: 0px !important;
position: absolute !important;
z-index: 2;
border: 1px solid #413f3f !important;
}

form .col-md-3
{
  display: flex;
  align-items: center;
}
form .col-md-3 label
{
  margin-bottom: 0px !important;
}
form .col-md-9 .custom-file-upload
{
  margin-bottom: 0px !important;

}
.border_blue_select .select__value-container
{
border-color: #59615f !important;
/* min-height: 52px !important;
max-height: 100% !important;
height: unset !important; */
}

.container .form_cekc_show .rc-checkbox [type=checkbox]
{
width: 15px !important;
height: 15px !important;
opacity: 1 !important;
position: relative !important;
}
.cur_pointer_icon
{
  cursor: pointer;
}
#update-contact .col-md-9
{
  color:#fff !important;
}
.no_underline
{
  text-decoration: none !important;
}
.list-group-item.active,.list-group-item[aria-current="page"],
.list-group-item:hover
{
  border-color: transparent !important;
  color: var(--themeyellow) !important;
}
.sidebar_whole .sidebar-submenu a {
  background-color: #242424 !important;
}
.sidebar_whole .sidebar-submenu a:hover{
  background-color: var(--themeyellow) !important;
  color: #000 !important;
}
.thumb-vertical {
  background: var(--themeyellow) !important;
}
.select__value-container
{
  padding-right: 40px !important;
}

.scroll_green_textarea::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
.scroll_green_textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scroll_green_textarea::-webkit-scrollbar-thumb {
  background-color: #02c076;
}
.scroll_green_textarea
{
  min-height: 80px !important;
}
.form-control:disabled, .form-control[readonly],.form-control {
  background-color: #e9ecef00 !important;
  color: #fff !important;
}
.cke_reset.cke_chrome {
  filter: invert(1);
}
.cursor{
  cursor: pointer !important;
}
.w-full{
  width: 100%;
}
.noshrink{
  flex-shrink: 0;
}
.track-vertical
{
  width: 6px;
    background: #424242;
    height: 100%;
    top: 0px;
    right: 0px;
}
.sidebar_whole .list-group-item.active
{
  z-index: unset !important;
}

html,body{
  scrollbar-color: #effb4d #424242  !important;
  scrollbar-width: thin !important;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #424242;
}
*::-webkit-scrollbar-thumb {
  background-color: #effb4d;
}


.flex_center .floatinglabel .right a.view_eyw_icon_color:hover

{
color:#ECFF03 !important;
}